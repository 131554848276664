<template>
	<div>
		<div class="dangkou-wrap" v-for="(dangkou,index) in listData" :key="index">
			<div class="dangkou-info">
				<div class="dangkou-header">
					<i class="el-icon-s-shop dangkou-icon"></i>
					<div class="name-wrap">
						<div class="name">{{dangkou.sc_bh}}</div>
						<div class="label"></div>
					</div>
				</div>
				<div class="desc-wrap">
					<div class="desc-item">宝贝数：{{dangkou.product_total}}件商品</div>
				</div>
				<div class="btn-wrap">
					<div class="btn active" @click="openDetail('./supplier?id='+dangkou.supplier_user_id)" >进入档口</div>
					<div class="btn" :class="dangkou.collect==1?'active':''" @click="collect(index)">{{dangkou.collect==1?'取消收藏':'收藏档口'}}</div>
				</div>
			</div>
			<ul class="product_list">
				<li class="product" v-for="(item,pidx) in dangkou.product_list" :key="pidx">
					<div class="img_box" @click="openDetail('./detail?id='+item.product_id)" >
						<el-image :src="item.img" fit="cover" lazy></el-image>
					</div>
					<div class="con_box">
						<!-- <a class="title" @click="openDetail('./detail?id='+item.product_id)">{{item.name}}</a> -->
						<div class="price_line">
							<p class="price">￥<em>{{item.discount_price}}</em></p>
						</div>
						
					</div>
					
				</li>
				
			</ul>
		</div>
		
		<li v-if="listData.length==0 && !loading" style="margin: 0 auto;">
			<el-empty description="暂无任何档口"></el-empty>
		</li>
		<li v-if="loading" style="margin: 0 auto;display: flex;flex-direction: column;align-items: center;color: #999;margin-top: 20px;">
			<div><i style="font-size: 30px;" class="el-icon-loading"></i></div>
			<div style="font-size: 13px;margin-top: 10px;">加载中...</div>
		</li>
	</div>
</template>

<script>
import Vue from 'vue';
import "swiper/swiper-bundle.min.css";
import Swiper, { Navigation } from "swiper";
import { mapActions,mapState,mapMutations } from "vuex";
import shopList from '../../components/shopList/shopList';
export default {
	name: "productList",
	props:{
		listData:{
			type:Array,
			default:[]
		},
		loading: {
			type: Boolean,
		},
    
	},
	components:{
		shopList
	},
	data() {
		return {
			
		};
	},
  computed:{
    ...mapState({
      users:(state)=>state.login.userinfo
    })
  },
	mounted(){
	},
	methods:{
		...mapActions({
			doCollectSup: "index/doCollectSup",
		}),
		
		openDetail(routePath){
			const fullPath = this.$router.resolve(routePath).href;  
			window.open(fullPath, '_blank');  
		},
		//收藏档口
		collect(index) {
			let dangkou_list = this.listData
			let supplier_user_id = dangkou_list[index].supplier_user_id
			
		  if (this.collect_loading) return
		  this.collect_loading = true
		  let param = {
		    supplier_user_id: supplier_user_id,
		  }
		  this.doCollectSup({
		    data: param, success: (res) => {
		      if (res.code === 200) {
		        this.$message({message: res.msg, type: 'success',});
		        dangkou_list[index].collect = res.data
						this.listData = dangkou_list
		      } else {
		        this.$message({message: res.msg, type: 'error',});
		      }
		      setTimeout(()=>{
		        this.collect_loading = false
		      },1000)
		    }
		  })
		},
		
	}
}
</script>

<style scoped>
.dangkou-wrap{display: flex;align-items: center;border-radius: 10px;box-shadow: 0 0 4px rgba(0,0,0,0.1);margin-bottom: 20px;padding: 0 20px 20px;}
.dangkou-wrap .dangkou-info{display: flex;flex-direction: column;flex: 1;margin-right: 20px;}
.dangkou-wrap .dangkou-info .dangkou-header{display: flex;align-items: center;border-bottom: 1px solid #eeeeee;padding: 10px 0;}
.dangkou-wrap .dangkou-info .dangkou-header .name-wrap{display: flex;align-items: center}
.dangkou-wrap .dangkou-info .dangkou-header .name-wrap .name{font-size: 20px;color: #333333;}
.dangkou-wrap .dangkou-info .dangkou-header .dangkou-icon{font-size: 30px;color: #fa6565;}
.dangkou-wrap .dangkou-info .desc-wrap{display: flex;flex-direction: column;font-size: 16px;color: #666666;margin-top: 20px;}
.dangkou-wrap .dangkou-info .btn-wrap{display: flex;align-items: center;margin-top: 20px;}
.dangkou-wrap .dangkou-info .btn-wrap .btn{padding: 4px 20px;font-size: 16px;border-radius: 5em;display: flex;align-items: center;justify-content: center;color: #333333;border: 1px solid #666;background: #fff;margin-right: 20px;cursor: pointer;}
.dangkou-wrap .dangkou-info .btn-wrap .btn.active{color: #fa6565; border: 1px solid #fa5757;}

.product_list{padding-top: 5px;display: flex;flex-wrap: nowrap;}
.product_list .product{width: 180px;height: auto;box-sizing: border-box;margin: 15px 15px 0 0;position: relative;}
.product_list .product .select-btn{position: absolute;left: 10px;top: 10px;}
.product_list.four .product:nth-child(4n){margin-right: 0;}
.product_list.five .product:nth-child(5n){margin-right: 0;}
.product .img_box{width: 100%;height: 180px;overflow: hidden;cursor: pointer;}
.product .img_box img{transition: opacity .5s;}
.product .img_box:hover img{opacity: .4;}
.product .con_box{padding: 10px 10px 15px;}
.product .con_box .title{height: 40px;font-size: 14px;line-height: 20px;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;color: #333333;cursor: pointer;}
.product .con_box .title:hover{color: #fa5757;text-decoration: underline;}
.product .con_box .price_line{margin-top: 15px;display: flex;justify-content: space-between;line-height: 1;align-items: flex-end;}
.product .con_box .price_line .price{color: #f0423f;}
.product .con_box .price_line .price>em{font-size: 24px;}
.product .con_box .info_line{margin-top: 12px;display: flex;justify-content: space-between;line-height: 1;}
.product .con_box .info_line .sales{font-size: 12px;color: #999;width: 50%;text-overflow:ellipsis;white-space: nowrap;text-align: right;align-items: flex-end;}
.product .con_box .info_line .supplier{font-size: 12px;color: #999;width: 50%;text-overflow:ellipsis;white-space: nowrap;}
.product .con_box .info_line .supplier:hover{
  color: #fa5757;
  text-decoration: underline;
  cursor: pointer;
}
.product .btn_wrap{border-top: 1px solid #eeeeee;display: flex;}
.product .btn_wrap .btn{flex: 1;height: 34px;display: flex;justify-content: center;align-items: center;box-sizing: border-box;border-right: 1px solid #eeeeee;font-size: 14px;color: #666666;cursor: pointer;}
.product .btn_wrap .btn:last-child{border-right: none;}
.product .btn_wrap .btn:hover{color: #fa5757;}
.product .btn_wrap .btn.active{color: #fa5757;}
</style>
<style>
.ph_main .ph_sel .el-checkbox__label{display: none !important;}
</style>