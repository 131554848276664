<template>
	<div class="wrap">
		<myHead></myHead>

		<div class="wrap_body" v-loading="is_loading">
			<div class="full-center">
				<div class="center">
					<div class="search_wrap">
						<div class="search_box">
							
							<span class="type el-dropdown-link">搜档口
							</span>
							<input class="in"
								placeholder="输入档口号或店招名称"
								v-model="searchParams.name" @keyup.enter="refresh()" />
						</div>
						<button class="search_btn" @click.stop="refresh()">搜索</button>
					</div>
					<div class="filter-title" v-if="false">
					<div class="filter-box" :class="showFilter?'show':'hide'">
						<div class="filter-header">
							<div class="header-right" @click="bindFilterShow()">
								<span>隐藏筛选</span>
								<i class="el-icon-arrow-up el-icon--up"></i>
							</div>
						</div>
						<div class="filter-list">
							<div class="label">地区</div>
							<div class="list">
								<div class="item" :class="!searchParams.site_id?'active':''" @click="bindSiteTap(0)">全部</div>
								<div class="item" :class="searchParams.site_id==item.site_id?'active':''" v-for="(item,index) in siteList" :key="index" @click="bindSiteTap(item.site_id)">{{item.site_name}}</div>
							</div>
						</div>
						<div class="filter-list">
							<div class="label">市场</div>
							<div class="list">
								<div class="item" :class="!searchParams.market_id?'active':''" @click.stop="bindMarketTap(0)">全部</div>
								<template v-for="(market,index) in marketList">
									<div class="item" :class="searchParams.market_id==market.market_id?'active':''"
										@click.stop="bindMarketTap(market.market_id)">{{market.market_name}}</div>
								</template>
							</div>
						</div>
						<div class="filter-list">
							<div class="label">类目</div>
							<div class="list">
								<div class="item" :class="!searchParams.cate_id[0]?'active':''" @click.stop="bindCateGroupTab(null)">全部</div>
								<div class="item" v-for="(cate,index) in cateGroupList" :key="index" :class="searchParams.cate_id[0]==cate.cate_ids?'active':''" @click="bindCateGroupTab(cate)">{{cate.name}}</div>
							</div>
						</div>
					
					</div>
				</div>
			</div>
		</div>
		<div class="center">
			<div class="home_main">

				<div class="product_section">
					<dangkouList style="padding: 0px 20px 20px;" :listData="list" :loading="dangkou_loading" ref="dangkous"></dangkouList>

					<div class="turn_page">
						<Pagination :total="page_data.total" :page.sync="page_data.page" :limit.sync="page_data.limit"
							@pagination="getDangkou" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[20,50,100,200,500,1000]" />
					</div>

				</div>
			</div>
		</div>
	</div>
	<myFoot :showBeian="true"></myFoot>
	</div>
</template>

<script>
	import Vue from 'vue';
	import "swiper/swiper-bundle.min.css";
	import {
		mapActions,
		mapState,
		mapMutations
	} from "vuex";
	import Swiper, {
		Navigation,
		Autoplay
	} from "swiper";
	import myHead from '../../components/myHead/myhead';
	import myFoot from '../../components/myFoot/myfoot';
	import dangkouList from '../../components/dangkouList/dangkouList';
	import common from '../../assets/js/utils/common';
	import headDefault from '../../assets/images/head.png';
	import Pagination from '../../components/pagination/pagination';

	export default {
		name: "shop",
		data() {
			return {
				headDefault,
				input1: '',
				input2: '',
				is_login: false,
				is_register: false,
				login_type: 1,
				reg_step: 1,
				list: [],
				reg_phone: "",
				reg_code: "",
				reg_pwd: "",
				reg_pwd_confirm: "",
				remember_pwd: false,
				is_get_phone_code: false,
				is_reg_submit: false,
				reg_checked: false,
				phone_second: 60,
				imageCode: "",
				login_phone: "",
				login_pwd: "",
				login_code: "",
				is_login_submit: false,
				checkBindTimer: null,
				userId: "",
				loadingCode: false,
				userinfo: "",
				avatar: "",
				is_loading: false,
				dangkou_loading: false,
				cateGroupList: [],
				currGroupName: '',
				subCateList: [],
				market: null,
				searchParams: {
					cate_id: [0, 0],
					site_id: 0,
					market_id: 0,
					sort_type: 0,
					name_type: 0
				},
				page_data: {
					limit: 10,
					page: 1,
					total: 0,
				},
				bannerList: [],
				bannerSwiper: null,
				newsList: [],
				orderCounts: {
					no_pay: 0,
					carry: 0,
					delivery: 0,
					after_sales: 0
				},
				yqm: "",
				yq_grade: "",
				checkList: [],
				marketList: [],
				site_index: 0,
				siteList: [],
				searchText: '',
				showFilter: true,
				filterText: '',
				checkExtList: [],
				ageingList: [],
				publicList: [],
				showPublic: false,
				showAgeing: false,
				ageing_text: '',
				public_text: '',
				timeRange: '',
				showPublicRange: false,
				
			}
		},
		components: {
			myHead,
			myFoot,
			dangkouList,
			Pagination
		},
		mounted() {
			// this.$nextTick(function () {
			// 	window.addEventListener('scroll', this.onScroll)
			// })
			// this.checkLoginCookie()
		},
		computed: {
			...mapState({
				users: (state) => state.login.userinfo,
				avatars: (state) => state.login.avatar,
				ucmenu: (state) => state.login.ucmenu,
			}),
		},
		created() {
			this.userinfo = this.users ? this.users : ""
			this.avatar = this.avatars || headDefault
			var query = this.$route.query
			if (query.reg) {
				this.is_register = true
				this.yqm = query.yqm
				this.yq_grade = query.yq_grade
			}
			if(query.name_type){
				this.searchParams.name_type = 1
			}
			if(query.name){
				this.searchParams.name = query.name
				this.searchText = query.name
			}
			if(query.site_id){
				this.searchParams.site_id = parseInt(query.site_id)
			}
			if(query.cate_1){
				this.searchParams.cate_id[0] = parseInt(query.cate_1)
			}
			if(query.cate_2){
				this.searchParams.cate_id[1] = parseInt(query.cate_2)
			}
			if(query.market_id){
				this.searchParams.market_id = parseInt(query.market_id)
			}
			
			if(query.page){
				this.page_data.page = parseInt(query.page)
			}
			
			this.init()
		},
		destroyed() {
			// window.removeEventListener('scroll', this.onScroll)
		},
		methods: {
			...mapActions({
				getMallCateData: "index/getMallCateData",
				getDangkouList: "index/getDangkouList",
			}),
			...mapMutations({
				OUT_LOGIN: "login/OUT_LOGIN"
			}),

			init() {
				// this.getCateList()
				this.getDangkou()
				
			},
		
			gotoIndexSearch() {
				let param = this.searchParams
				this.$router.push({
					name: 'search_index',
					query: param
				})
			},
			
			//获取商品分类
			getCateList() {
				this.is_loading = true
				this.getMallCateData({
					data: {},
					success: (res) => {
						if (res.code == 200 && res.data.lists.length > 0) {
							this.cateGroupList = res.data.lists
							if(!this.searchParams.cate_id[0]){
								this.subCateList = this.cateGroupList[0].cate_list[0].children
							}else{
								let cateGroup = this.cateGroupList.find(val => val.cate_ids == this.searchParams.cate_id[0])
								this.subCateList = cateGroup.cate_list[0].children
							}
							this.marketList = res.data.market_list
							this.siteList = res.data.site_list
							this.ageingList = res.data.ageing_list
							this.publicList = res.data.public_list
						}
						this.is_loading = false
						
					}
				})
			},
			//获取商品列表
			getDangkou() {
				if (this.dangkou_loading) return
				this.dangkou_loading = true
				this.list = []
				let searchParams = this.searchParams
				let param = {
					limit: this.page_data.limit,
					page: this.page_data.page,
					state: this.state
				}
				Object.assign(param, searchParams)
				
				// console.log(param)
				this.getDangkouList({
					data: param,
					success: (res) => {
						if (res.code === 200) {

							this.list = res.data.data
							this.page_data.total = res.data.total
						} else {
							this.$message({
								message: res.msg,
								type: 'error',
							});
						}
						this.dangkou_loading = false
						console.log(66655,this.dangkou_loading)
						
					}
				})
			},
			//滚动加载
			onScroll() {
				// 滚动条到最顶端的距离
				let scrolled = document.documentElement.scrollTop || document.body.scrollTop;
				let clientHeight = document.documentElement.clientHeight
				let scrollHeight = document.documentElement.scrollHeight
				if (scrolled + clientHeight + 400 >= scrollHeight) {
					this.loadMore()
				}
			},
			//刷新列表
			refresh() {
				this.page_data.page = 1
				this.getDangkou()
				// this.getOrderCount()
			},
			//清空筛选
			reset() {
				this.searchParams.cate_id = [0,0]
				this.searchParams.site_id = 0
				this.searchParams.market_id = 0
				this.subCateList = []
				
				this.refresh()
			},
			bindFilterShow(){
				this.showFilter = !this.showFilter
			},
			//加载商品
			loadMore() {
				if (this.page_data.total <= this.list.length) {
					return false
				} else {
					this.getDangkou()
				}
			},

			searchTap(name, val) {
				if (!this.searchParams[name] || this.searchParams[name] != val) {
					this.searchParams[name] = val
				} else {
					this.searchParams[name] = 0
				}
				this.refresh()
			},
			siteCommand(val) {
				let siteList = this.siteList
				this.site_index = val
				this.$set(this.searchParams, 'site_id', siteList[val].site_id)
				this.refresh()
			},
			typeCommand(val) {
				
				// this.refresh()
			},
			
			bindEventStop() {
				return false;
			},
			bindCateGroupTab(row) {
				if(row){
					this.subCateList = row.cate_list[0].children
					this.searchParams.cate_id[0] = parseInt(row.cate_ids)
				}else{
					this.subCateList = []
					this.searchParams.cate_id[0] = 0
				}
				this.searchParams.cate_id[1] = 0
				this.refresh()
			},
		
			bindCateTap(second_cid) {
				this.searchParams.cate_id[1] = second_cid
				this.refresh()
			},
			
		}
	}
</script>

<style scoped>
	.full-center {
		width: 100%;
		background-color: #fff;
		padding: 30px 0 34px
	}

	.register_main {
		width: 100%;
		height: auto;
		box-sizing: border-box;
		background: #fff;
		padding: 0 22px 30px;
	}

	.register_main .icon-guanbi {
		font-size: 12px;
		color: #999;
		position: absolute;
		top: 16px;
		right: 16px;
		cursor: pointer;
		z-index: 222;
	}

	.register_main .title {
		line-height: 1;
		font-size: 20px;
		margin-bottom: 16px;
	}

	.register_main .in_box {
		width: 100%;
		height: 38px;
		box-sizing: border-box;
		border: 1px solid #ddd;
		border-radius: 4px;
		display: flex;
		margin-top: 14px;
		align-items: center;
	}

	.register_main .in_box .txt {
		margin-left: 10px;
		width: 70px;
		font-size: 14px;
		color: #999;
	}

	.register_main .in_box .in {
		flex: 1;
		width: 0;
		margin-right: 14px;
		font-size: 14px;
		color: #333333;
	}

	.register_main .in_box .get_code {
		font-size: 14px;
		color: #333333;
		padding: 0 12px;
		border-left: 1px solid #ccc;
		line-height: 1;
		cursor: pointer;
	}

	.register_main .register_btn {
		width: 100%;
		height: 38px;
		border-radius: 4px;
		background: #fb5758;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #ffffff;
		font-size: 14px;
		cursor: pointer;
		margin-top: 35px;
	}

	.register_main .check_line {
		display: flex;
		align-items: center;
		font-size: 14px;
		margin-top: 14px;
	}

	.register_main .code {
		display: block;
		width: 155px;
		height: 155px;
		margin: 16px auto 0;
	}

	.register_main .code_txt {
		margin-top: 28px;
		text-align: center;
		font-size: 14px;
		color: #666666;
	}

	.register_main .check_line .link {
		color: #fb5758;
		cursor: pointer;
	}

	.login_main {
		width: 100%;
		height: auto;
		box-sizing: border-box;
		background: #fff;
		padding: 36px 0 28px;
	}

	.login_main .icon-guanbi {
		font-size: 12px;
		color: #999;
		position: absolute;
		top: 16px;
		right: 16px;
		cursor: pointer;
		z-index: 222;
	}

	.login_main .login_type {
		padding: 14px 0;
		border-bottom: 1px solid #eee;
		display: flex;
		line-height: 1;
	}

	.login_main .login_type .tab {
		flex: 1;
		text-align: center;
		font-size: 18px;
		cursor: pointer;
	}

	.login_main .login_type .tab.active {
		font-weight: bold;
		color: #fb5758;
	}

	.login_main .code_login {
		margin: 38px 0 55px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.login_main .code_login .code {
		width: 155px;
		height: 155px;
	}

	.login_main .code_login .txt {
		margin-top: 24px;
		line-height: 1;
		font-size: 14px;
		color: #666666;
		text-align: center;
	}

	.login_main .account_login {
		margin: 0 22px 55px;
		padding-top: 20px;
	}

	.login_main .account_login .in_box {
		width: 100%;
		height: 38px;
		box-sizing: border-box;
		border: 1px solid #ddd;
		border-radius: 4px;
		display: flex;
		margin-top: 14px;
		align-items: center;
	}

	.login_main .account_login .in_box .icons {
		width: 38px;
		height: 100%;
		border-right: 1px solid #ddd;
		background: #f5f5f5;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.login_main .account_login .in_box .icons .iconfont {
		font-size: 20px;
		color: #cccccc;
	}

	.login_main .account_login .in_box .in {
		flex: 1;
		width: 0;
		margin: 0 14px;
		font-size: 14px;
		color: #333333;
	}

	.login_main .account_login .in_box .txt {
		margin-left: 10px;
		width: 60px;
		font-size: 14px;
		color: #999;
	}

	.login_main .account_login .in_box .get_code {
		font-size: 14px;
		color: #666;
		padding: 0 12px;
		border-left: 1px solid #ccc;
		line-height: 1;
		cursor: pointer;
	}

	.login_main .account_login .change_way {
		margin-top: 20px;
		line-height: 1;
		display: flex;
		justify-content: space-between;
	}

	.login_main .account_login .change_way>span {
		font-size: 14px;
		color: #333333;
		cursor: pointer;
	}

	.login_main .account_login .login_btn {
		width: 100%;
		height: 38px;
		border-radius: 4px;
		background: #fb5758;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #ffffff;
		font-size: 14px;
		cursor: pointer;
		margin-top: 35px;
	}

	.login_main .go_register {
		margin: 0 30px;
		text-align: right;
		font-size: 14px;
		line-height: 1;
	}

	.login_main .go_register>a {
		color: #fb5758;
		text-decoration: underline;
		cursor: pointer;
	}

	.home_main {
		width: 100%;
		height: auto;
		margin-top: 20px;
		margin-bottom: 40px;
	}

	.search_wrap {
		margin: 0 auto;
		display: flex;
		justify-content: center;
		font-size: 16px;
	}

	.search_wrap .search_box {
		width: 900px;
		height: 50px;
		box-sizing: border-box;
		background-color: #ffffff;
		border-radius: 4px 0vw 0vw 4px;
		border: solid 2px #fa5757;
		display: flex;
		align-items: center;
	}

	.search_wrap .search_box .type {
		width: 84px;
		color: #333333;
		cursor: pointer;
		text-align: center;
		line-height: 26px;
		border-right: 1px solid #ddd;
	}

	.search_wrap .search_box .in {
		flex: 1;
		width: 0;
		margin: 0 15px;
		color: #333333;
		font-size: 16px;
	}

	.search_wrap .search_box .icon-xiangji {
		margin-right: 14px;
		font-size: 24px;
		color: #999;
		cursor: pointer;
	}

	.search_wrap .search_btn {
		width: 132px;
		height: 50px;
		background-image: linear-gradient(180deg, #fa5757 0%, #fb6d6d 100%), linear-gradient(#ffffff, #ffffff);
		background-blend-mode: normal, normal;
		border-radius: 0vw 4px 4px 0vw;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #ffffff;
		font-weight: bold;
		cursor: pointer;
	}
	
	.filter-title {
	    width: 100%;
	    margin-top: 30px
	}
	
	.filter-title .search-input {
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center
	}
	
	.filter-title .search-input .label {
	    font-size: 14px;
	    color: #666
	}
	
	.filter-title .search-input .val {
	    font-size: 14px;
	    color: #333;
	    margin-left: 10px
	}
	
	.filter-box {
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-orient: vertical;
	    -webkit-box-direction: normal;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    margin-top: 14px;
	    overflow: hidden;
	    width: 1200px;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    background-color: #fff
	}
	
	.filter-box .filter-header {
	    -webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	    justify-content: space-between;
	    padding: 0 21px;
	    min-height: 46px;
	    background-color: #f9f9f9;
	    border: 1px solid #eee;
	    font-size: 14px;
	    width: 100%;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box
	}
	
	.filter-box .filter-header .header-left,.filter-box .filter-header {
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center
	}
	
	.filter-box .filter-header .header-left .label {
	    font-size: 14px;
	    color: #666
	}
	
	.filter-box .filter-header .header-left .val {
	    font-size: 14px;
	    color: #000;
	    margin-left: 20px
	}
	
	.filter-box .filter-header .header-left .reset {
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center;
	    margin-left: 20px;
	    cursor: pointer
	}
	
	.filter-box .filter-header .header-left .reset span {
	    color: #fa5757
	}
	
	.filter-box .filter-header .header-left .reset i {
	    color: #fa5757;
	    margin-left: 4px
	}
	
	.filter-box .filter-header .header-right {
	    cursor: pointer
	}
	
	.filter-box .filter-header .header-right i {
	    margin-left: 8px
	}
	
	.filter-box .filter-list {
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    padding: 0 21px;
	    min-height: 46px;
	    line-height: 46rpx;
	    font-size: 14px;
	    border-bottom: 1px solid #eee;
	    width: 100%;
	    border-left: 1px solid #eee;
	    border-right: 1px solid #eee;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box
	}
	
	.filter-box.show {
	    max-height: 1200px;
	    -webkit-transition: all .5s;
	    transition: all .5s;
	    overflow-y: scroll
	}
	
	.filter-box::-webkit-scrollbar {
	    width: 0
	}
	
	.filter-box.hide {
	    max-height: 46px;
	    -webkit-transition: all .5s;
	    transition: all .5s
	}
	
	.filter-box .filter-list .label {
	    width: 55px;
	    line-height: 46px;
	    color: #999
	}
	
	.filter-box .filter-list .list {
	    width: calc(100% - 55px)
	}
	
	.filter-box .filter-list .list .item {
	    line-height: 46px;
	    display: inline-block;
	    margin-right: 50px;
	    color: #333;
	    cursor: pointer
	}
	
	.filter-box .filter-list .list .item.active {
	    color: #fa5757
	}

	.top_section {
		margin-top: 30px;
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.top_section .left_type {
		width: 270px;
		height: 490px;
		background-color: #ffffff;
		box-sizing: border-box;
		padding-bottom: 12px;
		padding-top: 24px;
		position: relative;
	}

	.left_type .type_show {
		width: 100%;
		height: 100%;
		overflow-y: scroll;
	}

	.left_type .type_show .title {
		display: flex;
		align-items: center;
		line-height: 1;
		font-weight: bold;
		margin-bottom: 10px;
		padding: 0 20px;
	}

	.left_type .type_show .title .icon-fenlei2 {
		font-size: 16px;
		margin-right: 8px;
	}

	.left_type .type_show .title .site_name {
		font-weight: bold;
		cursor: pointer;
	}

	.left_type .type_show .title .el-icon-arrow-down {
		font-weight: bold;
	}

	.left_type .type_show .type_line {
		width: 100%;
		min-height: 32px;
		display: flex;
		align-items: flex-start;
		padding: 0 10px 0 20px;
		box-sizing: border-box;
		font-size: 14px;
		flex-direction: column;
	}

	.left_type .type_show::-webkit-scrollbar {
		width: 5px;
		height: 5px;
	}

	.left_type .type_show::-webkit-scrollbar-track {
		background: #efefef;
	}

	.left_type .type_show::-webkit-scrollbar-thumb {
		background: #bfbfbf;
	}

	.left_type .type_show::-webkit-scrollbar-corner {
		background: #333;
	}

	.left_type .type-head {
		display: flex;
		align-items: center;
		width: 100%;
	}

	.left_type .type-head .icon-wrap {
		display: inline-flex;
		height: 32px;
		width: 20px;
		align-items: center;
		justify-content: center;
	}

	.left_type .type-head .icon-wrap .cate-icon {
		width: 20px;
		height: 20px;
		object-fit: contain;
	}

	.left_type .type_show .type_line .iconfont {
		font-size: 18px;
		display: none;
	}

	.left_type .type_show .type_line .con {
		flex: 1;
		width: 0;
		margin-left: 10px;
		font-weight: bold;
		color: #333333;
	}

	.left_type .type_show .type_line .con>span:hover {
		color: #fa5757;
		text-decoration: underline;
		cursor: pointer;
	}

	.left_type .type_show .type_line .con>em {
		font-weight: bold;
		padding: 0 6px;
	}

	.left_type .type_show .type_line .con>em:last-child {
		display: none;
	}

	.left_type .type_show .type_line:hover {
		background: #ffedee;
	}

	.left_type .type_show .type_line.hover {
		background: #ffedee;
	}

	.left_type .type_show .type_line.hover .con>span {
		color: #fa5757;
		cursor: pointer;
	}

	.left_type .type_show .type_line .sub-cate-wrap {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: unset;
		margin-bottom: 10px;
	}

	.left_type .type_show .type_line .sub-cate-wrap .sub-cate {
		line-height: 32px;
		margin-right: 10px;
		display: inline-block;
		cursor: pointer;
		color: #666666;
	}

	.left_type .type_show .type_line .sub-cate-wrap .sub-cate.active {
		color: #fa5757;
		text-decoration: underline;
	}

	.left_type .type_show .type_line .el-icon-arrow-right {
		font-weight: bold;
	}

	.left_type .type_show .type_line:hover .el-icon-arrow-right {
		color: #fa5757;
	}

	.left_type .type_show .type_line .sub-cate-wrap .sub-cate:hover {
		color: #fa5757;
	}

	.left_type .detail_hide {
		width: 930px;
		height: auto;
		box-sizing: border-box;
		position: absolute;
		top: 0;
		left: 270px;
		z-index: 22;
		background: #fff;
		box-shadow: 0 0 10px 0 #eeeeee;
		visibility: hidden;
		opacity: 0;
		transition: all .3s ease-in-out;
	}

	.left_type .detail_hide.active {
		visibility: visible;
		opacity: 1;
	}

	.detail_hide .detail_label {
		height: auto;
		min-height: 300px;
		box-sizing: border-box;
		padding: 30px 30px 40px;
	}

	.detail_hide .detail_label:first-child {
		margin-top: 0;
	}

	.detail_hide .detail_label .lv1 {
		color: #e4393c;
		font-weight: bold;
		margin-bottom: 6px;
		font-size: 16px !important;
		border-bottom: 1px solid #eeeeee;
		padding: 15px 0;
	}

	.detail_hide .detail_label .label_line {
		margin-top: 12px;
		font-size: 12px;
		line-height: 24px;
		display: flex;
	}

	.detail_hide .detail_label .label_line .tab_lv2 {
		font-weight: bold;
		width: 90px;
		padding-right: 20px;
		display: none;
	}

	.detail_hide .detail_label .label_line .tab_lv2 .lv2 {
		cursor: pointer;
	}

	.detail_hide .detail_label .label_line .tabs_wrap {
		flex: 1;
		width: 0;
		display: flex;
		flex-wrap: wrap;
	}

	.detail_hide .detail_label .label_line .tabs_wrap .tab_lv3 {
		margin-right: 15px;
		cursor: pointer;
		line-height: 3;
		width: 130px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #666666;
	}

	.detail_hide .detail_label .label_line .tabs_wrap .tab_lv3:hover {
		color: #fa5757;
	}

	.detail_hide .detail_label .label_line .tabs_wrap .tab_lv3.active {
		color: #fa5757;
		text-decoration: underline;
	}

	.label_line .tab_lv2 .lv2:hover,
	.label_line .tabs_wrap .tab_lv3:hover {
		color: #e4393c;
	}

	.top_section .center_main {
		width: 600px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.center_main .swiper_wrap {
		width: 100%;
		height: 256px;
	}

	.center_main .swiper_wrap .swiper {
		height: 100%;
	}

	.center_main .swiper_wrap .swiper_btn {
		width: 26px;
		height: 50px;
		background: rgba(0, 0, 0, .4);
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 22;
	}

	.center_main .swiper_wrap .swiper_btn.prev {
		left: 0;
	}

	.center_main .swiper_wrap .swiper_btn.next {
		right: 0;
	}

	.center_main .swiper_wrap .swiper_btn .icon-fanhui {
		font-size: 20px;
		color: #ffffff;
	}

	.center_main .swiper_wrap .swiper_btn:hover .icon-fanhui {
		color: #fa5757;
	}

	.center_main .swiper_wrap .swiper_btn.next .icon-fanhui {
		transform: rotate(180deg);
	}

	.center_main .adx_box {
		width: 100%;
		height: 223px;
		display: block;
	}

	.top_section .right_con {
		width: 308px;
		display: flex;
		flex-direction: column;
		background: #fff;
		position: relative;
	}

	.right_con .supplier_login_wrap {
		width: 100%;
		text-align: center;
		margin-top: 10px;
		color: #000;
	}

	.right_con .supplier_login_wrap .supplier_login_btn {
		font-size: 14px;
		color: #000 !important;
	}

	.right_con .supplier_login_wrap .supplier_login_btn:hover {
		color: var(--red) !important;
	}

	.right_con .user_wrap {
		padding: 28px 16px 26px;
		background: #faedee;
	}

	.right_con .user_wrap .user_con {
		display: flex;
		align-items: center;
	}

	.right_con .user_wrap .user_con .head_box {
		width: 54px;
		height: 54px;
		background: #ffffff;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
	}

	.right_con .user_wrap .user_con .head_box .iconfont {
		font-size: 34px;
		color: #fdaeae;
	}

	.right_con .user_wrap .user_con .head_box .head {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 50%;
	}

	.right_con .user_wrap .user_con .con {
		flex: 1;
		width: 0;
		margin-left: 15px;
		font-size: 14px;
		line-height: 1;
	}

	.right_con .user_wrap .user_con .con .txt {
		margin-top: 8px;
		color: #999;
	}

	.right_con .user_wrap .btn_wrap {
		margin-top: 18px;
		display: flex;
		justify-content: space-between;
	}

	.right_con .user_wrap .btn_wrap .btn {
		width: 130px;
		height: 32px;
		box-sizing: border-box;
		border-radius: 5em;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
		cursor: pointer;
	}

	.right_con .user_wrap .btn_wrap .btn:nth-child(1) {
		background: #fa5757;
		color: #ffffff;
	}

	.right_con .user_wrap .btn_wrap .btn:nth-child(2) {
		background: #fff;
		color: #fa5757;
		border: 1px solid #fa5757;
	}

	.right_con .order_nums {
		width: 100%;
		height: 88px;
		border-bottom: 1px solid #eeeeee;
		display: flex;
		align-items: center;
	}

	.right_con .order_nums .num_box {
		flex: 1;
		text-align: center;
		line-height: 1;
		cursor: pointer;
	}

	.right_con .order_nums .num_box .num {
		font-size: 20px;
	}

	.right_con .order_nums .num_box .txt {
		font-size: 14px;
		color: #666666;
		margin-top: 10px;
	}

	.right_con .news_wrap {
		flex: 1;
		height: 0;
		padding: 18px 16px 0;
	}

	.right_con .news_wrap .news_img {
		width: 91px;
		height: 18px;
		margin-bottom: 4px;
	}

	.right_con .news_wrap .news_link {
		margin-top: 12px;
		font-size: 14px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		display: block;
		color: #333333;
		line-height: 1;
		cursor: pointer;
	}

	.right_con .news_wrap .news_link:hover {
		color: #fa5757;
		text-decoration: underline;
	}

	.product_section {
	    width: 100%;
	    height: auto;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    background: #fff;
	    margin-top: 20px;
	    padding-top: 20px
	}
	
	.product_section .screen_wrap {
	    position: relative;
	    width: calc(100% - 40px);
	    height: 46px;
	    border-bottom: 1px solid #eee;
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center;
	    margin: 0 20px;
	    background-color: #f9f9f9;
	    border: 1px solid #eee;
	    padding: 0 20px;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box
	}
	
	.screen_wrap .title {
	    font-weight: 700;
	    margin-right: 60px
	}
	
	.screen_wrap .tab {
	    font-size: 14px;
	    margin-right: 40px;
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center;
	    cursor: pointer
	}
	
	.screen_wrap .tab:hover {
	    color: var(--red);
	    /* text-decoration: underline */
	}
	
	
	.screen_wrap .tab.active {
	    color: #fa5757
	}
	
	.screen_wrap .in_box {
	    width: 54px;
	    height: 24px;
	    border: 1px solid #ccc;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    padding: 0 6px;
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center;
	    font-size: 14px;
	    color: #999
	}
	
	.screen_wrap .in_box .in {
	    margin-left: 6px
	}
	
	.screen_wrap .in_line {
	    width: 20px;
	    color: #999
	}
	
	.screen_wrap .in_btn,.screen_wrap .in_line {
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-pack: center;
	    -ms-flex-pack: center;
	    justify-content: center;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center
	}
	
	.screen_wrap .in_btn {
	    margin-left: 12px;
	    width: auto;
	    padding: 0 10px;
	    height: 26px;
	    background: #ddd;
	    cursor: pointer;
	    font-size: 12px;
	    color: #666
	}
	
	.ext-screen {
	    position: relative;
	    width: calc(100% - 40px);
	    height: 46px;
	    height: 42px;
	    background-color: #fff;
	    border-left: 1px solid #eee;
	    border-right: 1px solid #eee;
	    border-bottom: 1px solid #eee;
	    margin: 0 20px;
	    -webkit-box-sizing: border-box;
	    box-sizing: border-box;
	    padding: 0 20px;
	    -webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	    justify-content: space-between
	}
	.ageing-list{
		position: absolute;
		width: 1160px;
		left: 0;
		top: 0;
		height: 42px;
		background-color: #fff;
		box-shadow: 0 4px 4px rgba(0, 0,0,0.1 );
		z-index: 10;
	}
	.ageing-list,.ext-screen .check-list,.ext-screen {
	    display: -webkit-box;
	    display: -ms-flexbox;
	    display: flex;
	    -webkit-box-align: center;
	    -ms-flex-align: center;
	    align-items: center;
	}
	
	.ageing-list .ageing-item {
	    font-size: 12px;
	    color: #333;
	    margin-left: 25px;
	    cursor: pointer
	}
	
	.ageing-list .ageing-item.active {
	    color: #fa5757
	}
	
	
	/deep/.ext-screen .check-list .el-checkbox__inner {
	    width: 16px;
	    height: 16px
	}
	
	/deep/.ext-screen .check-list .el-checkbox__label {
	    font-size: 12px!important
	}
	
	/deep/.el_input {
	    margin: 14px auto 0!important;
	    width: 100%
	}
	
	.screen_wrap .totle-text {
	    position: absolute;
	    right: 20px;
	    font-size: 14px;
	    color: #666
	}
</style>
<style>
	.ageing-box{
		width: 100vw !important;
		position: absolute !important;
		left: 50% !important;
		background: unset !important;
		transform: translateX(-580px);
		box-shadow: unset !important;
		border: unset !important;
	}
	.register_mask .el-dialog__body,
	.login_mask .el-dialog__body,
	.login_mask .el-dialog__header {
		padding: 0;
	}

	.el_input.el_in2 .el-input__prefix {
		top: 0;
		left: 0;
	}

	.el_input {
		display: flex;
		margin-top: 14px;
	}

	.el_input .el-input__inner {
		height: 38px;
		line-height: 38px;
		padding-left: 58px;
		flex: 1;
		width: 0;
	}

	.el_input .el-input__inner:focus {
		border-color: var(--red);
	}

	.el_input.el_in2 .el-input__inner {
		padding-left: 90px;
	}

	.account_login .el_input .el-input__prefix {
		left: 1px;
		top: 1px;
		height: 36px;
	}

	.el_input .el-input__prefix .icons {
		width: 38px;
		height: 36px;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 1px;
		left: 2px;
		background: #f5f5f5;
		border-right: 1px solid #ddd;
		border-radius: 4px 0 0 4px;
	}

	.el_input .el-input__prefix .icons .iconfont {
		font-size: 20px;
		color: #cccccc;
	}

	.el_input .el-input__prefix .txts {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		padding-left: 10px;
	}

	.el_input .el-input__suffix {
		display: flex;
	}

	.el_input .el-input__suffix .el-input__suffix-inner {
		display: flex;
		align-items: center;
	}

	.el_input .el-input__suffix .el-input__suffix-inner .getcode {
		font-size: 14px;
		color: #333333;
		padding: 0 12px;
		border-left: 1px solid #ccc;
		line-height: 1;
		cursor: pointer;
	}

	.register_main .check_line .el-checkbox {
		margin-right: 8px;
		margin-top: 2px;
	}

	.el-checkbox__input.is-focus .el-checkbox__inner {
		border-color: #DCDFE6 !important;
	}

	.el-checkbox__input.is-checked .el-checkbox__inner {
		background-color: #fb5758 !important;
		border-color: #fb5758 !important;
	}

	.el-dropdown-menu__item:focus,
	.el-dropdown-menu__item:not(.is-disabled):hover {
		background-color: #ffedee;
		color: #fa5757;
	}
</style>